.timeline {
    position: relative;
    width: 660px;
    margin: 0 auto;
    margin-top: 20px;
    padding: 1em 0;
    list-style-type: none;
  }
  
  .timeline:before {
    position: absolute;
    left: 50%;
    top: 0;
    content: ' ';
    display: block;
    width: 6px;
    height: 100%;
    margin-left: -3px;
    background: rgb(80,80,80);
    background: -moz-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(30,87,153,1)), color-stop(100%,rgba(125,185,232,1)));
    background: -webkit-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    background: -o-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    background: -ms-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    background: linear-gradient(to bottom, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    
    z-index: 5;
  }
  
  .timeline li {
    padding: 1em 0;
  }
  
  .timeline li:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
  
  .direction-l {
    position: relative;
    width: 300px;
    float: left;
    text-align: right;
  }
  
  .direction-r {
    position: relative;
    width: 300px;
    float: right;
  }
  
  .flag-wrapper {
    position: relative;
    display: inline-block;
    
    text-align: center;
  }
  
  .flag {
    position: relative;
    display: inline;
    font-family: var(--font-family);
    color: #ff8a71;
    padding: 6px 10px;
    border-radius: 5px;
    font-size: 25px;
    font-weight: 600;
    text-align: left;
  }
  
  .direction-l .flag {
    -webkit-box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    -moz-box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  }
  
  .direction-r .flag {
    -webkit-box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  }
  
  .direction-l .flag:before,
  .direction-r .flag:before {
    position: absolute;
    top: 50%;
    right: -40px;
    content: ' ';
    display: block;
    width: 12px;
    height: 12px;
    margin-top: -10px;
    background: linear-gradient(45deg, rgb(185, 76, 219), rgb(226, 96, 146) 50%, rgb(254, 129, 94));
    border-radius: 10px;
    border: 3px solid #71E5FF;
    z-index: 10;
  }
  
  .direction-r .flag:before {
    left: -40px;
  }
  
  .direction-l .flag:after {
    content: "";
    position: absolute;
    left: 100%;
    top: 50%;
    height: 0;
    width: 0;
    margin-top: -8px;
    border: solid transparent;
    border-left-color: #71E5FF;
    border-width: 8px;
    pointer-events: none;
  }
  
  .direction-r .flag:after {
    content: "";
    position: absolute;
    right: 100%;
    top: 50%;
    height: 0;
    width: 0;
    margin-top: -8px;
    border: solid transparent;
    border-right-color: #71E5FF;
    border-width: 8px;
    pointer-events: none;
  }
  

  .direction-l .time-wrapper {
    float: left;
  }
  
  .direction-r .time-wrapper {
    float: right;
  }
  
  
  
  .desc {
    color: #71E5FF;
    font-size: 21px;
    text-align: left;
    font-family: 'Raleway', sans-serif;
    letter-spacing: 0.7px;
    width: 220px;
    
    
    
  }
  
  .direction-r .desc {
    margin: 1em 0 0 0.75em;
  }
  
  /* ================ Timeline Media Queries ================ */
  
  @media screen and (max-width: 660px) {
  
  .timeline {
       width: 100%;
      padding: 4em 0 1em 0;
  }
  
  .timeline li {
      padding: 2em 0;
  }
  
  .direction-l,
  .direction-r {
      float: none;
      width: 100%;
  
      text-align: center;
  }
  
  .flag-wrapper {
      text-align: center;
  }
  
  .flag {
      
      z-index: 15;
  }
  
  .direction-l .flag:before,
  .direction-r .flag:before {
    position: absolute;
    top: -30px;
      left: 50%;
      content: ' ';
      display: block;
      width: 12px;
      height: 12px;
      margin-left: -9px;
      background: linear-gradient(45deg, rgb(185, 76, 219), rgb(226, 96, 146) 50%, rgb(254, 129, 94));
      border-radius: 10px;
      border: 4px solid #71E5FF;
      z-index: 10;
  }
  
  .direction-l .flag:after,
  .direction-r .flag:after {
      content: "";
      position: absolute;
      left: 50%;
      top: -8px;
      height: 0;
      width: 0;
      margin-left: -8px;
      border: solid transparent;
      border-bottom-color:#71E5FF;
      border-width: 8px;
      pointer-events: none;
  }
  
  .time-wrapper {
      display: block;
      position: relative;
      margin: 4px 0 0 0;
      z-index: 14;
  }
  
  .direction-l .time-wrapper {
      float: none;
  }
  
  .direction-r .time-wrapper {
      float: none;
  }
  
  .desc {
      position: relative;
      margin: 1em 0 0 0;
      padding: 1em;
      color: #71E5FF;
      background:   #031B34;
      opacity: 80%;
      width: 350px;

      border-bottom-left-radius: 28px;
      border-bottom-right-radius: 28px;
      border-top-left-radius: 28px;
      border-top-right-radius: 28px;
      
      -webkit-box-shadow: 0 0 1px rgba(0,0,0,0.20);
      -moz-box-shadow: 0 0 1px rgba(0,0,0,0.20);
      box-shadow: 0 0 1px rgba(0,0,0,0.20);
      
    z-index: 15;
  }
  
  .direction-l .desc,
  .direction-r .desc {
      position: relative;
      margin: 1em 1em 0 1em;
      padding: 1em;
      
    z-index: 15;
  }
  
  }
  
  @media screen and (min-width: 400px) {
  
  .direction-l .desc,
  .direction-r .desc {
      margin: 1em 4em 0 4em;
  }
  
  }