.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 6rem;
}

.navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
}

.navbar-links_logo {
    margin-right: 2rem;
    font-family: var(--font-family);
    color: #FFF;
    cursor: pointer;
}

.portfolio__navbar-links_logo img {
   width: 62px;
   height: 16px;
}

.navbar-links-container {
    display: flex;
    flex-direction: row;
    color: #FFF;
    font-family: var(--font-family);
    
    
}

.navbar-links-container p {
    
    color: #FFF;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.navbar-links-icons, 
.navbar-menu-links-icons {
    display: flex;
    flex-direction: row;
    color: #FFF;
}

.navbar-git-icon, .navbar-linked-icon, .navbar-insta-icon {
transition: 0.3s ease;
border-radius: 50px;

padding: 8px;
margin: 0 1rem;
}

.navbar-git-icon:hover, .navbar-linked-icon:hover, .navbar-insta-icon:hover  {

background-color: #212d45;
transform: scale(1.2);
cursor: pointer;
        
    
}

.navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
    cursor: pointer;
}

.navbar-menu-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2)
}

.navbar-menu-container p {
    margin: 1rem 0;
}

.navbar-menu-container-links {
    color: #FFF;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

@media screen and (max-width: 1050px) {
    .navbar-links-container {
        display: none;
    }

    .navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .navbar {
        padding: 2rem 4rem;
    }

}

@media screen and (max-width: 550px) {
    .navbar {
        padding: 2rem;
    }

    .navbar-links-icons {
        display: none;
    }

    .nav-bar-menu-container {
        top: 2px;
    }


}

