.blog {
    display: flex;
    flex-direction: column;  
}

.button {
    display: inline-block;
    border-radius: 15px;
    background-color: #f4511e;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 18px;
    padding: 20px;
    width: 160px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
    margin-top: 30px;
    font-family: var(--font-family);
    opacity: 90%;
    letter-spacing: 2px
  }
  
  .button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  
  .button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }
  
  .button:hover span {
    padding-right: 25px;
  }
  
  .button:hover span:after {
    opacity: 1;
    right: 0;
  }

.blog-heading {
    width: 100%;
    text-align: left;
    margin-bottom: 5rem;
}

.blog-heading h1 {
    font-size: 62px;
    line-height: 75px;
    font-family: var(--font-family);
    font-weight: 800;
}

.blog-container {
    box-sizing: border-box;
    column-gap: 28px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 600px;
    line-height: 19.2px;
    margin-bottom: 28px;
   
}

@media screen and (max-width: 990px) {
    .blog-container {
        flex-direction: column-reverse;
    }
    
    .container-article-left { 
        padding: 65px 50px 50px 50px;
    }

    .blog-container_groupA {
        margin: 2rem 0;
    }

    .blog-container {
    
        display: flex;
        
    }
}

@media screen and (max-width: 700px) {
   
    .blog-container {
    
        display: flex;
    }

}

@media screen and (max-width: 550px) {
    .blog-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

.blog-container {
    
    display: flex;
    
}

}