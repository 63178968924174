.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    border-color: rgba(131, 131, 131, 0.16);
    border-width: 1px; 
    border-style: solid;
    background: var(--color-footer);
}

.footer-heading {
    width: 100%;
    text-align: left;
    margin-top: 100px;
    margin-bottom: 1rem;
    
}

.contact-me h4 {
    color: #ff8a71;
    font-family: var(--font-family);
    font-size: 17px;
    margin-left: 6rem;
    
}

.contact-me {
   
}

.footer-heading h4 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 24px;
    color: #fff;
}

.footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    text-align: left;
}

.footer-links div {
    width: 250px;
    margin: 2.2rem;
    margin-top: 0.6rem;
}

.footer-links_logo {
    display: flex;
    flex-direction: column;
}


.footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    
}

.footer-links_div h4 {
    font-size: 17px;
    line-height: 17px;
    font-family: var(--font-family);
    color: #ff8a71;

    margin-bottom: 0.9rem;
}

.footer-links_div p {
    font-size: 14px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #fff;

    margin: 0.5rem 0;
    cursor: pointer;
}

.footer-copyright {
    margin-bottom: 1rem;
    text-align: center;
    width: 100%;
   
}

.footer-copyright p {
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 15px;
    color: #fff;
}

@media screen and (max-width: 850px) {
    .footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .footer-heading  {
        text-align: center;
    }


    .footer-links_div{
        display: none;
    }

    .footer-links {
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 400px) {
    .footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }

}